import logo from './static/img/logo.png'

import { useEffect, useState } from 'react';
import { Amplify } from 'aws-amplify';
import { getCurrentUser, signOut, fetchUserAttributes } from 'aws-amplify/auth';

import config from '../amplifyconfiguration.json';

Amplify.configure(config);

function Header(){
    const [logged, setlogged] = useState(false);
    const [attributes, setattributes] = useState({});

    const signout = async () => {
        await signOut();
        setlogged(false);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getCurrentUser();
                const userAttributes = await fetchUserAttributes();
                setattributes(userAttributes);
                setlogged(true);
            } catch (err) {
                setlogged(false);
            }
        }
        fetchData();
    },[])
    return(
        <header className="c-header">
            <nav className="c-nav">
                <a href="/"><img className="c-logo" src={logo} alt="Logo"/></a>
                <ul className="c-nav__menu">
                    <li className="c-nav__item"><a href="/#about">About Us</a> </li>
                    <li className="c-nav__item"><a href="/#modules">Modules</a> </li>
                    <li className="c-nav__item"><a href="/contact">Contact Us</a> </li>
                    {logged?
                        <li className="c-nav__item"><a href="/user">{attributes.name}</a> </li>
                    :
                        <li className="c-nav__item"><a href="/login#login">Log In</a> </li>
                    }
                    {logged?
                        <li className="c-nav__item"><a href="/" onClick={signout}>LogOut</a> </li>
                    :
                        <li className="c-nav__item"><a href="/login#signup">Sign-Up</a> </li>
                    }
                    
                </ul>
            </nav>
        </header>
    )
}

export default Header