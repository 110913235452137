export async function getPrismicRef(){
    const response = await fetch(`${process.env.REACT_APP_PRISMIC_HOST}`);
    if(response.status === 200){
        const data = await response.json();
        
        return data.refs[0].ref;
    }
    return
}

export async function getPrismicResults(ref){
    const response = await fetch(`${process.env.REACT_APP_PRISMIC_HOST}/documents/search?ref=${ref}`);
    if(response.status === 200){
        const data = await response.json();
        return data.results;
    }
    return
}